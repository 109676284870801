:root{
    --rmsc-main: #4285f4;
    --rmsc-hover: var(--bg-light) !important;
    --rmsc-selected: var(--bg-light) !important;
    --rmsc-border: #ccc;
    --rmsc-gray: #aaa;
    --rmsc-bg: var(--bg-light) !important;
    --rmsc-p: 10px !important;
    --rmsc-radius: 1px !important;
    --rmsc-h: 38px;
  }

.multi-select .dropdown-container{
    border:none !important;
    background: var(--bg-light) !important;
    color: var(--text-dimmed);
    box-shadow: 2px 2px 3px var(--shadow-color-dark), -2px -2px 3px var(--shadow-color-light) !important;
}

.multi-select-item{
    border: none;
    background: var(--bd-light)
}

 .dropdown-content div{
    border-radius: 0 !important;
    background: var(--bg-light) !important;
    border: 1px solid var(--bg-light) !important;

}