main {
  margin-bottom: 10em;
}

p {
  color: var(--text-dimmed);
}

.btn {
  font-family: "Lora";
}

.btn-gw-primary-outline {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.inner-shadow {
  box-shadow: inset 3px 3px 6px var(--shadow-color-dark), inset -5px -5px 6px var(--shadow-color-light);
}

.outer-shadow {
  box-shadow: 3px 3px 5px var(--shadow-color-dark), -3px -3px 5px var(--shadow-color-light);
}

.inner-outer-shadow {
  box-shadow: 3px 3px 5px var(--shadow-color-dark), -3px -3px 5px var(--shadow-color-light), inset 2px 2px 10px var(--shadow-color-dark), inset -4px -4px 10px var(--shadow-color-light);
}

.heading-6 {
  font-weight: 700;
}

.brand-name {
  font-size: 48px;
}
.brand-name .sub-text {
  font-size: 28px;
}

.brand-name:hover {
  color: var(--accent-color);
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-name {
  line-height: 10px;
}

.content {
  width: 30%;
  display: flex;
  justify-content: center;
  margin-left: 18px;
}

.logout {
  opacity: 0.5;
  cursor: pointer;
}
.logout:hover {
  opacity: 1;
  color: red;
}

.content.avatar {
  margin-left: -2em;
}

.column {
  border-radius: 10px;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items:  center;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 500;
  font-family: "Lora";
  cursor: pointer;
}
.column p {
  margin: 0;
}

.column i {
  margin: 0 10px;
  font-size: 22px;
}

.column-sm, .column-xs {
  border-radius: 10px;
  width: 30%;
  display: flex;
  justify-content: center;
  padding-top: 10px;
  font-size: 14px;
  font-family: "Lora";
  cursor: pointer;
}

.column-xs {
  margin-top: 5px;
  width: 70%;
  font-size: 12px;
}

.column-sm i {
  margin: 0 10px;
  padding-top: 3px;
  font-size: 16px;
}

.column-xs i {
  margin-left: 5px;
  margin-top: 4px;
}

.receipt-list {
 overflow-x: scroll;
}

.action-btn {
  position: relative;
  width: 40px;
  height: 40px;
  font-size: 18px;
  display: grid;
  place-content: center;
}

.action-btn .badge {
  position: absolute;
  top: 5px;
  left: 20px;
  background: var(--bg-light);
  color: red !important;
  border-radius: 50%;
  font-size: 10px;
}

.desc {
  font-size: 14px;
  line-height: 1.8;
  padding-left: 5px
}

.det-container {
  margin: 1rem 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
 
}

.det-container span{
  display: inline-block;
  font-size: 12px;
  margin-bottom: 10px;
}

.check-name {
  font-family: "Lora";
  font-weight: 700;
  line-height: 1rem;
  font-size:  .875rem;
}

.logout-icon, .logout-icon-sm {
  width: 50px;
  height: 50px;
  border: 1px solid #e5ebe7;
  padding: .9em;
  color: var(--accent-color) !important;
}

.logout-icon-sm {
  margin-top: 1.5em;
}

.mobile-header {
  border-radius: 24px;
}

.custom-select, input[type=text], input[type=date], .custom-select:focus, input[type=text]:focus, input[type=date]:focus {
  border: none;
  background-color: var(--bg-light);
  border-radius: 16px;
}

.pick-date {
  position: relative;
  display: inline-block;
}

.pick-date::before {
  position: absolute;
  content: " \25BE";
  top: 6px;
  right: 0;
  height: 20px;
  width: 20px;
}

.date {
  font-size: 14px;
}

.desc {
  font-size: 16px;
  line-height: 1.8;
  padding-left: 5px
}

.filter select {
  width: 90%;
}

.btn {
  text-transform: unset;
}

.items * {
  margin: auto 2px;
}

.items {
  border-radius: 3px;
  display: grid;
  grid-template-columns: 5fr 2fr 2fr 1fr;
}

.items select {
  font-size: 12px;
  width: 130px;
}

.items a {
   margin-left: 1em;
}

.file label {
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 0 18px;
  border-radius: 20px;
  margin-top: 6px;
}

#file-upload {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.submit-file button {
  font-size: 20px;
}

.wrap {
  display: flex;
}
.wrap > button {
  width: 25%;
}

.mobile-footer {
  position: fixed;
  left: 5px;
  right: 5px;
  border-radius: 15px;
  bottom: 0;
  background-color: var(--bg-light);
  box-shadow: 3px 3px 5px var(--shadow-color-dark), -3px -3px 5px var(--shadow-color-light);

}

.mobile-footer a {
  display: grid;
  place-content: center;
  font-size: 1.5rem;
  color: var(--accent-color);
  padding: 15px;
  border-radius: 7px;
  width: 60px;
  height: 60px;
  text-align: center;
}

.mobile-footer .active, .nav-active {
  box-shadow: inset 3px 3px 5px var(--shadow-color-dark), inset -5px -5px 6px var(--shadow-color-light);
}

.modal-body {
  background-color: var(--bg-light);
}



.item-avatar{
  border-radius: 50%;
  border: 2px solid var(--accent-color);
}
.item-name {
  margin:0;
  font-weight: bold;
  color: var(--text-dark);
  font-family:  "Lora", serif;
  font-size: .825rem;
}
.placeholder-sm{
  display: inline-block;
  width: 35px;
  height: 10px;
  background: var(--accent-color);
}
.placeholder-lg{
  display: inline-block;
  width: 100%;
  height:35px;
  background: var(--text-dark);
}
.placeholder-exp{
  
  width: 100%;
  height: 45px;
  background: var(--text-dimmed);
}
.req-cont{
  border-radius:  15px;
 
}

.submit-btn{
  display: inline-block;
}

.error-list-item{
  color: rgb(187, 39, 39)
}


.project-header h5{
  display: inline-block;
  font-family: 'Lora', serif;
  font-weight: bold;
  margin: 0;
  margin-right: .5rem;
  font-size: 1.5rem;
}
.project-card{
  border-radius: 15px;
  cursor: pointer;
  display:  block;
  color: var(--text-dark);
}
.project-card:hover{
  color:var(--accent-color);
}
.project-card button.btn{
  transition: transform 400ms linear;
}
.project-card:hover button.btn{
  transform: translateX(100%);
}
.project-card  p{
  font-size: .875rem;
  line-height: 1.9;
}

.list input.item-input, .list input.item-input:focus{
  border-radius: 3px;
  padding: .5rem 1rem;
  border: none;
  outline: none;
}

input.search-sm {
  max-width: 150px;
  border: none;
  height: 30px;
  font-size: 12px;
  padding: 2px 10px;
  background: var(--bg-light);
}

input[type=text].search-sm:focus {
  border: none;
  outline: none;
}

@media (max-width: 1000px) {
  .header-container, .action-section {
    display: none;
  }
  .items * {
    margin: 5px 0;
  }
  .items h5,
  .items p,
  .items a {
    font-size: 16px;
    font-weight: normal;
  }
  .edit {
    margin-top: -12%;
  }
  .edit a {
    float: right;
  }
  .items {
    width: 100%;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .items a {
    margin-left: 0;
  }
  .beneficiary > div {
    margin: 0 3px;
  }
  .column-sm {
    padding-top: 10px;
    font-size: 12px;
  }
  .column-sm i {
    margin-left: 8px;
    padding-top: 2px;
    font-size: 12px;
  }
  .req-cont{
    border-radius:  15px;
    padding-bottom: 10rem;
  }
  .figures {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  .figures div:nth-of-type(2) {
    margin-top: 0;
  }
  .heading-6 {
    font-size: 14px;
  }
  .receipt-list {
    margin: 1em 0;
    padding-bottom: .5em;
  }
  .container{
    padding-bottom: 8rem;
  }
  .check-buttons {
    margin: 0;
    padding: 0;
    margin-top: -1em;

  }
  .check-buttons button {
    font-size: .8em !important;
  }
  .action-btn-container{
    display: flex;
    justify-content: space-between;
  }
  .activity>div{
    border-bottom:  1px groove var(--accent-color);
    padding: 3rem .5rem;
  }
}

@media (min-width: 1000px) {
  .action-btn-container {
    display: flex;
    justify-content: center;
  }
  .activity>div:hover {
    box-shadow: inset 3px 3px 5px var(--shadow-color-dark), inset -5px -5px 6px var(--shadow-color-light);
    border-radius: 10px;
    transition: box-shadow 1200ms linear, border-radius 900ms ease-in-out;
  }
  .source select {
    width: 75%;
  }
}